import styles from './MobileHeader.module.scss'
import logo from '../../Images/fabrica-logo.svg'

export default function MobileHeader() {
    return (
        <div className={styles.MobileHeader}>
            <img 
                className={styles.Logo}
                src={logo} 
                alt="Fabrica"
            />
            <h1>Developments</h1>
        </div>
    )
}