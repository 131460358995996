import styles from './Keyboard.module.scss';

import Key from '../Key';

function Keyboard({characters, addCharacter, removeCharacter, search, canSearch = true, disabled = false}) {

    const keyRows = characters.map((row, i) => (
        <div className={styles.Row} key={i}>
            {row.map((character, j) => (
                <Key 
                    key={j}
                    character={character}
                    onTouch={() => addCharacter(character)}
                    disabled={disabled}
                />
            ))}
        </div>
    ))

    if(keyRows.length) {
        const lastRow = keyRows.pop();
        keyRows.push(
            <div className={styles.Row} key="lastrow">
                <Key 
                    character={'Backspace'} 
                    special 
                    onTouch={() => removeCharacter()}
                />
                {lastRow}
                <Key 
                    character={'Search'} 
                    special 
                    light
                    onTouch={() => canSearch && search()}
                    disabled={!canSearch}
                />
            </div>
        )
        keyRows.push(
            <div className={styles.SpacebarRow} key={'spacebar_row'}>
                <Key 
                    key={'space'}
                    character={'space'}
                    onTouch={() => addCharacter(" ")}
                    spacebar
                    disabled={disabled}
                />
            </div>
        )
    }

    return (
        <div className={styles.Keyboard}>
            {keyRows}
        </div>
    );
}

export default Keyboard;
