import styles from './Predictions.module.scss'
import PredictionPill from '../PredictionPill/PredictionPill'

export default function Predictions({predictions, selectPrediction}) {
    const predictionPills = predictions.map((prediction, i) => <PredictionPill text={prediction.description} onSelect={() => selectPrediction(i)} key={i} />)

    return (
        <div className={styles.Predictions}>
            {predictionPills}
        </div>
    )
}