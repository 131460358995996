import { useEffect, useRef, useState } from 'react';
import Keyboard from './Components/Keyboard/Keyboard';
import styles from './SearchModal.module.scss'
import searchIcon from '../../Images/icon-search-light.svg'
import closeIcon from '../../Images/icon-x.svg'
import PredictionPill from './Components/PredictionPill/PredictionPill';
import Predictions from './Components/Predictions/Predictions';


export default function SearchModal({closeModal, onSearch, isEmbedded}) {
    const [text, setText] = useState("");
    const [predictions, setPredictions] = useState([])

    const inputRef = useRef()
    const autoCompleteRef = useRef();
    const geocoderRef = useRef();

    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.AutocompleteService();
        geocoderRef.current = new window.google.maps.Geocoder();
    }, []);

    useEffect(() => {
        if(text) {
            autoCompleteRef.current.getPlacePredictions({input: text, componentRestrictions: {country: 'gb'}}).then(response => setPredictions(response.predictions))
        } else {
            setPredictions([])
        }
    }, [text])

    const disabled = text.length <= 30 ? false : true;

    const addCharacter = (character) => {
        if (!disabled) {
            setText(text.concat(character));
        }
    };

    const removeCharacter = () => {
        setText(text.slice(0, -1));
    };

    const selectPrediction = (index) => {
        if(predictions.length) {
            geocoderRef.current.geocode({ 'placeId': predictions[index]['place_id']}).then(response => {
                onSearch(response['results'][0])
                closeModal()
            })
        }
    }

    return (
        <div className={styles.SearchModal}>
            <div className={styles.Inner}>
                <Predictions predictions={predictions} selectPrediction={selectPrediction}/>
                <form
                    className={styles.Form}
                    onSubmit={(e) => {
                        e.preventDefault()
                        selectPrediction(0)
                    }}
                >
                    <img 
                        className={styles.searchIcon}
                        src={searchIcon}
                        alt="Search"
                    />
                    <input
                        className={styles.Input}
                        type="text"
                        value={text}
                        placeholder="Search by Location"
                        ref={inputRef}
                        onChange={e => setText(e.target.value)}
                        autoFocus
                    />
                </form>

                {!isEmbedded && <Keyboard
                    characters={[
                        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
                        ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
                        ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
                        ["z", "x", "c", "v", "b", "n", "m"],
                    ]}
                    addCharacter={addCharacter}
                    removeCharacter={removeCharacter}
                    search={() => selectPrediction(0)}
                    disabled={disabled}
                    canSearch={text.length ? true : false}
                />}
                
                <button
                    className={styles.closeButton}
                    onClick={() => closeModal()}
                >
                    <img 
                        src={closeIcon}
                        alt="Close"
                    />
                </button>
            </div>
        </div>
    )
}