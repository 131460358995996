import { useEffect, useMemo, useState } from 'react';
import useAirtableData from '../../Hooks/useAirtableData';
import styles from './App.module.scss';

import Sidebar from '../Sidebar';
import Map from '../Map'
import MobileHeader from '../MobileHeader/MobileHeader';
import EmbeddedHeader from '../EmbeddedHeader';

import { AirtableDataContext } from '../../Contexts/AirtableDataContext';
import { SelectedDevelopmentContext } from '../../Contexts/SelectedDevelopmentContext';
import { MapsAPILoadedContext } from '../../Contexts/MapsAPILoadedContext';
import { showCompletedDevelopmentsContext } from '../../Contexts/showCompletedDevelopmentsContext';
import SearchModal from '../SearchModal';

// Google maps API
import { useJsApiLoader } from '@react-google-maps/api';
import DevelopmentModal from '../DevelopmentModal/DevelopmentModal';
const libraries = ['places']

function App() {

  // const [isEmbedded, setIsEmbedded] = useState(true)

  // Load Google Mpas API
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDujLlERHTyEyiO4f1xY7uKBbHyuvaJJQ8",
    libraries: libraries
  })

  const [airtableData] = useAirtableData()
  const [selectedDevelopment, setSelectedDevelopment] = useState(null);
  const [currentSearch, setCurrentSearch] = useState(null)
  const [showSearchModal, setShowSearchModal] = useState(false)
  const [showCompletedDevelopments, setShowCompletedDevelopments] = useState(false)
  const [radius, setRadius] = useState(5)

  useEffect(() => {
    setShowCompletedDevelopments(false)
  }, [currentSearch])

  const searchRadius = useMemo(() => {
    return currentSearch ? {center: {lat: currentSearch.geometry.location.lat(), lng: currentSearch.geometry.location.lng()}, radius: radius} : null;
  }, [currentSearch, radius])

  const isEmbedded = useMemo(() => {
    const searchParams = new URLSearchParams(document.location.search)
    if(searchParams.get('embedded') === 'true') {
      return true;
    }
    return false
  })

  return (
    <div className={`${styles.App} ${isEmbedded ? styles.Embedded : ''}`}>
      {!isEmbedded && <MobileHeader />}
      {isEmbedded && <EmbeddedHeader openSearch={() => setShowSearchModal(true)} currentSearch={currentSearch} setCurrentSearch={setCurrentSearch} radius={radius} setRadius={setRadius}/>}

      <AirtableDataContext.Provider value={airtableData}>
        <MapsAPILoadedContext.Provider value={isLoaded}>
          <SelectedDevelopmentContext.Provider value={{selectedDevelopment, setSelectedDevelopment}}>
            <showCompletedDevelopmentsContext.Provider value={{showCompletedDevelopments, setShowCompletedDevelopments}}>
              <Map searchRadius={searchRadius} isEmbedded={isEmbedded}/>
              {!isEmbedded && <Sidebar openSearch={() => setShowSearchModal(true)} currentSearch={currentSearch} setCurrentSearch={setCurrentSearch} searchRadius={searchRadius} radius={radius} setRadius={setRadius}/>}
            </showCompletedDevelopmentsContext.Provider>
          </SelectedDevelopmentContext.Provider>
        </MapsAPILoadedContext.Provider>
      </AirtableDataContext.Provider>

      {/* Search Modal */}
      {showSearchModal && <SearchModal 
        closeModal={() => setShowSearchModal(false)}
        onSearch={setCurrentSearch}
        isEmbedded={isEmbedded}
      />}

      {/* Development modal for embedded version */}
      {(selectedDevelopment && isEmbedded) && <DevelopmentModal
        selectedDevelopment={selectedDevelopment} 
        developments={airtableData}
        closeModal={() => setSelectedDevelopment(null)}
      />}

    </div>
  );
}

export default App;
