import minusIcon from "../../../../Images/minus-regular.svg"
import plusIcon from "../../../../Images/plus-regular.svg"
import styles from "./MapControls.module.scss"

export default function MapControls({ map, zoom, setZoom }) {
  const increaseZoom = () => {
    setZoom((map?.getZoom() ?? zoom) + 1)
  }

  const decreaseZoom = () => {
    setZoom((map?.getZoom() ?? zoom) - 1)
  }

  return (
    <>
      <button className={styles.MapControl} onClick={increaseZoom}>
        <img className={styles.Icon} src={plusIcon} alt="Zoom In" />
      </button>
      <button className={styles.MapControl} onClick={decreaseZoom}>
        <img className={styles.Icon} src={minusIcon} alt="Zoom Out" />
      </button>
    </>
  )
}
