import { Marker, OverlayView } from '@react-google-maps/api';
import markerCurrent from '../../../../Images/marker-current.svg'
import markerComingSoon from '../../../../Images/marker-coming-soon.svg'
import markerComplete from '../../../../Images/marker-complete.svg'
import styles from './SingleMarker.module.scss'
import { SelectedDevelopmentContext } from '../../../../Contexts/SelectedDevelopmentContext';
import { useContext } from 'react';
import arrowIcon from '../../../../Images/icon-arrow-white.svg'
import popupCenter from '../../../../Utils/popupCenter'

export default function SingleMarker({development, clusterer}) {

    const {selectedDevelopment, setSelectedDevelopment} = useContext(SelectedDevelopmentContext)

    let markerIcon;
    switch(development['fields']['Status']) {
        case 'Current':
            markerIcon = markerCurrent;
            break;
        case 'Coming Soon':
            markerIcon = markerComingSoon;
            break;
        default:
            markerIcon = markerComplete;
    }

    const marker = (
        <Marker
            icon={{
                url: markerIcon,
                scale: 1,
            }}
            key={development['id']}
            position={{lat: development['fields']['Latitude'], lng: development['fields']['Longitude']}}
            onClick={() => setSelectedDevelopment(development['id'])}
            visible={true}
            clusterer={clusterer}
        />
    )

    const toolTip = (
        <OverlayView
            position={{lat: development['fields']['Latitude'], lng: development['fields']['Longitude']}}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => {  
                return {x: -(width / 2) + 1, y: -(height + 50)}
            }}
        >
            <div 
                className={styles.ToolTip} 
                onClick={(e) => {
                    if(development['fields']['URL']) {
                        if(window.innerWidth > 1200) {
                            popupCenter(development['fields']['URL'], development['fields']['Name'], "900", "500")
                        } else {
                            //Open in new tab. better ux for mobile 
                            e.preventDefault()
                            const newTab = window.open(
                                development['fields']['URL'],
                                "_blank",
                                "noopener,noreferrer"
                            );
                        }
                    }
                }}
            >
                {development['fields']['Logo'] && <img 
                    className={styles.Logo}
                    src={development['fields']['Logo']}
                    alt={development['fields']['Name']}
                />}
                <h4
                    className={styles.Name}
                >
                    {development['fields']['Name']}
                </h4>
                {development['fields']['URL'] && <img 
                    className={styles.Arrow}
                    src={arrowIcon} 
                    alt=""
                />}
            </div>
        </OverlayView>
    )

    return (
        <>
            {marker}
            {selectedDevelopment === development['id'] && toolTip}
        </>
    )
}