import SearchBar from '../SearchBar/SearchBar'
import styles from './EmbeddedHeader.module.scss'

export default function EmbeddedHeader({openSearch, currentSearch, setCurrentSearch, radius, setRadius}) {
    return (
        <div className={styles.EmbeddedHeader}>
            <h1>Developments</h1>

            <div className={styles.SearchBarContainer}>
                <SearchBar openSearch={openSearch} currentSearch={currentSearch} setCurrentSearch={setCurrentSearch} radius={radius} setRadius={setRadius} centerContainer={true}/>
            </div>
        </div>
    )
}