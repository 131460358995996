import Developments from './Components/Developments/Developments'
import Header from './Components/Header/Header'
import SearchBar from '../SearchBar/SearchBar'
import styles from './Sidebar.module.scss'
import { AirtableDataContext } from '../../Contexts/AirtableDataContext'
import { useContext, useMemo } from 'react'
import getDistances from './helpers'
import RadiusSelect from '../SearchBar/Components/RadiusSelect/RadiusSelect'

export default function Sidebar({openSearch, currentSearch, setCurrentSearch, searchRadius, radius, setRadius}) {
    const airtableData = useContext(AirtableDataContext)

    const sortedDevelopments = useMemo(() => searchRadius ? getDistances(searchRadius, airtableData.filter(d => d['fields']['Status'] !== 'Complete')) : null, [searchRadius, airtableData])

    return (
        <div className={styles.Sidebar}>
            <div className={styles.Header}>
                <Header />
            </div>
            <div className={styles.Search}>
                <SearchBar openSearch={openSearch} currentSearch={currentSearch} setCurrentSearch={setCurrentSearch} radius={radius} setRadius={setRadius}/>
            </div>
            <div className={styles.Developments}>
                <Developments developments={airtableData} sortedDevelopments={sortedDevelopments}/>
            </div>
        </div>
    )
}