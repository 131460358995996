// Packages
import React, { useState, useEffect, useContext } from 'react';
import { GoogleMap, Circle } from '@react-google-maps/api';

// Styles
import styles from './GoogleMaps.module.scss'

// Other
import {containerStyle, mapStyles} from './MapStylesConfig'
import ClusteredMarkers from '../ClusteredMarkers/ClusteredMarkers';
import MapCircle from '../MapCircle/MapCircle';
import MapControls from '../MapControls/MapControls';
import { MapsAPILoadedContext } from '../../../../Contexts/MapsAPILoadedContext';
import { SelectedDevelopmentContext } from '../../../../Contexts/SelectedDevelopmentContext';

function GoogleMaps ({developments, searchRadius}) {
    const mapsAPILoaded = useContext(MapsAPILoadedContext)
    const {selectedDevelopment, setSelectedDevelopment} = useContext(SelectedDevelopmentContext)

    // Hold map in state
    const [map, setMap] = useState(null);

    // Zoom
    const [zoom, setZoom] = useState(5);
    
    // Setup map once loaded
    const onLoad = React.useCallback(function callback(map) {
        map.fitBounds(setBoundsNew());
        setMap(map)
    }, [])
  
    // Clean up map on unmount
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    // Fit map to bounds when developments prop updated
    useEffect(() => {
        if(map && developments) {
          map.fitBounds(setBoundsNew())
        };
    }, [developments]);
  
    // Workout the new bounds
    const setBoundsNew = () => {
        const bounds = new window.google.maps.LatLngBounds();
        developments.forEach((development) => {
            bounds.extend({
                lat: development['fields']['Latitude'],
                lng: development['fields']['Longitude']
            })
        })
        return bounds
    }

    // Pan to selected development
    useEffect(() => {
        if(selectedDevelopment) {
            const panTo = developments.filter(development => development['id'] === selectedDevelopment)[0]
            map.panTo({
                lat: panTo['fields']['Latitude'],
                lng: panTo['fields']['Longitude']
            })
        }
    }, [selectedDevelopment])

    const onZoomChange = () => {
        const zoom = map?.getZoom();

        if (!zoom) return;
        setZoom(zoom);

        selectedDevelopment && setSelectedDevelopment(null)
    };

    return mapsAPILoaded && <div className={styles.GoogleMaps}>
        <GoogleMap
            mapContainerStyle={containerStyle}
              options={{
                styles: mapStyles,
                disableDefaultUI: true
              }}
            zoom={zoom}
            disableDefaultUI={true}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onZoomChanged={onZoomChange}
        >
            <ClusteredMarkers developments={developments} />
            {searchRadius && <MapCircle map={map} searchRadius={searchRadius} />}

            <div className={styles.mapControls}>
                <MapControls map={map} zoom={zoom} setZoom={setZoom} />
            </div>
        </GoogleMap>
    </div> 
  
  }
  
  export default React.memo(GoogleMaps)