import { GoogleMap, useJsApiLoader, MarkerClusterer } from '@react-google-maps/api';
import { useContext } from 'react';
import { showCompletedDevelopmentsContext } from '../../../../Contexts/showCompletedDevelopmentsContext';
import SingleMarker from '../SingleMarker';
import styles from './ClusteredMarkers.module.scss'
import { SelectedDevelopmentContext } from '../../../../Contexts/SelectedDevelopmentContext';

export default function ClusteredMarkers({developments}) {
    const {showCompletedDevelopments, setShowCompletedDevelopments} = useContext(showCompletedDevelopmentsContext)
    const {selectedDevelopment, setSelectedDevelopment} = useContext(SelectedDevelopmentContext)

    const clusterOptions = {
        imagePath: 'assets/cluster-markers/',
        imageExtension: 'svg',
        imageSizes: [60],
        clusterClass: styles.ClusterMarker
    }

    const filteredDevelopments = developments.filter((development) => {
        if(!showCompletedDevelopments) {
            if(development['fields']['Status'] === 'Complete') {
                return false
            }
        }

        return true
    }).filter(development => {
        if(selectedDevelopment) {
            if(development['id'] === selectedDevelopment) {
                return false
            }
        }

        return true
    })

    const selectedDevelopments = developments.filter((development) => {
        if(selectedDevelopment) {
            if(development['id'] === selectedDevelopment) {
                return true
            }
        }

        return false
    })

    return (
        <>
            <MarkerClusterer options={clusterOptions} minimumClusterSize={3} ignoreHidden={true}>
                {(clusterer) => filteredDevelopments.map(development => <SingleMarker clusterer={clusterer} key={development['id']} development={development}/>)}
            </MarkerClusterer>
            {selectedDevelopments.map(development => <SingleMarker key={development['id']} development={development}/>)}
        </>
    )
}