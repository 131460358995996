import styles from './DevelopmentModal.module.scss'
import closeIcon from '../../Images/icon-x.svg'
import arrowIcon from '../../Images/icon-arrow.svg'


export default function DevelopmentModal({closeModal, selectedDevelopment, developments}) {

    let development;
    developments.forEach(iDev => {
        if(iDev['id'] === selectedDevelopment) {
            development = iDev;
        }
    });

    const openDevInNewTab = (e, url) => {
        e.preventDefault()
        const newTab = window.open(
            url,
            "_blank",
            "noopener,noreferrer"
        );
    }

    return (
        <div className={styles.DevelopmentModal}>
            <div className={styles.Inner}>
               
                <div>
                    <div 
                        className={`${styles.DevelopmentCard} ${styles[development['fields']['Status'] === 'Coming Soon' ? 'ComingSoon' : development['fields']['Status']]} ${selectedDevelopment === development['id'] ? styles.Active : ''}`}
                    >
                        {(development['fields']['Logo'] && development['fields']['Status'] === 'Current') && 
                            <div className={styles.Logo}>
                                <img 
                                    src={development['fields']['Logo']}
                                    alt={development['fields']['Name']}
                                />
                            </div>
                        }
                        <div className={styles.Content}>
                            <div className={styles.Line}>
                                <h4 className={styles.Name}>{development['fields']['Name']}</h4>
                            </div>
                            <div className={styles.Line}>
                                <p className={styles.Address}>{development['fields']['Address']}</p>
                            </div>
                            {development['fields']['Description'] && 
                                <div className={styles.Line}>
                                    <p className={styles.Description}>{development['fields']['Description']}</p>
                                </div>
                            }
                        </div>
                            {development['fields']['Status'] === 'Current' && development['fields']['URL'] &&
                            <div 
                                className={styles.Icon}
                                onClick={e => openDevInNewTab(e, development['fields']['URL'])}
                            >
                                View Development
                                <img 
                                    src={arrowIcon} 
                                    alt=""
                                />
                            </div>}
                            {development['fields']['Status'] !== 'Current' && 
                            <div 
                                className={styles.Icon}
                            >
                                {development['fields']['Status']}
                            </div>}
                        </div>
                    </div>

                <button
                    className={styles.closeButton}
                    onClick={() => closeModal()}
                >
                    <img 
                        src={closeIcon}
                        alt="Close"
                    />
                </button>
            </div>
        </div>
    )
}