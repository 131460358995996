import { Marker } from "@react-google-maps/api";
import { useEffect, useRef } from "react"
import markerIcon from '../../../../Images/marker-radius.svg'

export default function MapCircle({map, searchRadius}) {
    const circleRef = useRef()

    useEffect(() => {
        if(map && searchRadius) {
            if(circleRef.current) {
                circleRef.current.setMap(null);
            }

            circleRef.current = new window.google.maps.Circle({
                strokeColor: "#AAC7CA",
                strokeOpacity: 0.55,
                strokeWeight: 2,
                fillColor: "#AAC7CA",
                fillOpacity: 0.40,
                map: map,
                center: searchRadius.center,
                radius: searchRadius.radius * 1609,
            });

            map.fitBounds(circleRef.current.getBounds());
        }

        return () => {
            if(map && circleRef.current) {
                circleRef.current.setMap(null);
            }
        }
    }, [map, searchRadius])

    return searchRadius && <Marker
        key={'radius-marker'}
        position={searchRadius.center}
        visible={false}
    />
}