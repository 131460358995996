import GoogleMaps from './Components/GoogleMaps'
import styles from './Map.module.scss'
import { AirtableDataContext } from '../../Contexts/AirtableDataContext';
import { useContext } from 'react';

export default function Map({searchRadius, isEmbedded}) {
    const airtableData = useContext(AirtableDataContext)

    return (
        <div className={`${styles.Map} ${isEmbedded ? styles.Embedded : ''}`}>
            <GoogleMaps 
                developments={airtableData}
                searchRadius={searchRadius}
            />
        </div>
    )
}