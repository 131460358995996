import styles from './PredictionPill.module.scss'

export default function PredictionPill({text, onSelect}) {
    return (
        <button 
            className={styles.PredictionPill}
            onClick={onSelect}
        >
            {text}
        </button>
    )
}