// Google Maps JS Styles
const containerStyle = {
    width: '100%',
    height: '100%'
};

// set styles for grayscale elements on the map
const grayscaleStylers = [
  { saturation: -100 },
  { gamma: 1.2 },
  { lightness: 4 }
];

const mapStyles = [
    {
      featureType: "administrative",
      stylers: [
        ...grayscaleStylers
      ]
    },
    {
      featureType: "landscape",
      stylers: [
        ...grayscaleStylers
      ]
    },
    {
      featureType: "road",
      stylers: [
        ...grayscaleStylers
      ]
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        { visibility: "off" }
      ]
    },
    {
      featureType: "poi.business",
      stylers: [
        { visibility: "off" }
      ]
    },
    {
      featureType: "poi",
      stylers: [
        ...grayscaleStylers
      ]
    },
    {
      featureType: "water",
      stylers: [
        ...grayscaleStylers
      ]
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
]

export {containerStyle, mapStyles}