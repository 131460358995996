import { useEffect, useMemo, useState } from "react";

import axios from "axios";

export default function useAirtableData() {
  const [airtableData, setAirtableData] = useState([]);
  const [airtableError, setAirtableError] = useState(null);
  const [airtableOffset, setAirtableOffset] = useState(null);


  const filterRecords = (records) => {
    return records
      .filter(records => records['id'] ? true : false)
      .filter(records => records['fields']['Latitude'] && records['fields']['Longitude'] ? true : false)
  }

  const getAirtableData = () => {
    setAirtableError(null);

    const url = "https://api.airtable.com/v0/appiEmdxMIPlaqvGU/developments"
    const filter = "";
    const offset = airtableOffset ? `&offset=${airtableOffset}` : "";

    axios
      .get(`${url}${filter}${offset}`, {
        headers: {
          Authorization: "Bearer patx59rLuwqKk6Ckn.fa3eb7d589ae656740208837faf56245b38c0f70743169c4fed15fc97e09e676",
        },
      })
      .then((response) => {
        response.data["records"]
          ? setAirtableData(
              airtableOffset
                ? [...airtableData, ...filterRecords(response.data["records"])]
                : [...filterRecords(response.data["records"])]
            )
          : setAirtableError(`No Results from airtable`);
        response.data["offset"]
          ? setAirtableOffset(response.data.offset)
          : setAirtableOffset(null);
      })
      .catch((error) => {
        setAirtableError(error);
      });
  };

  // Deal with pagination
  useEffect(() => {
    if (airtableOffset) {
      getAirtableData();
    }
  }, [airtableOffset]);

   // Log any errors
   useEffect(() => {
    if (airtableError) {
      console.error(airtableError);
    }
  }, [airtableError]);

  // Get data once
  useMemo(() => {
    getAirtableData()
  }, [])

  return [airtableData];
}
